<template>
  <div class="block lg:hidden">
    <LazyUSlideover v-model:open="isOpen">
      <ClientOnly>
        <UButton
          icon="i-heroicons-bars-3"
          color="neutral"
          variant="outline"
          aria-label="Theme"
          class="mr-1"
          @click="handleOpenMenu"
        />
        <template #fallback>
          <div class="w-8 h-8">
            <USkeleton class="w-7 h-7" />
          </div>
        </template>
      </ClientOnly>
      <template #content>
        <UCard
          class="flex flex-col flex-1"
          :ui="{
            header: 'px-4 py-[14px]',
          }"
        >
          <template #header>
            <div class="flex items-center justify-between">
              <Logo />
              <UButton
                color="neutral"
                variant="ghost"
                icon="i-heroicons-x-mark-20-solid"
                class="-my-1"
                @click="handleOpenMenu"
              />
            </div>
          </template>
          <UCommandPalette
            id="commandPaletteRef"
            ref="commandPaletteRef"
            class="mt-2"
            :empty-state="{
              label: LL.no_results(),
              queryLabel: LL.no_results(),
            }"
            :placeholder="`${LL.search()}...`"
            :groups="groups"
            :fuse="{ resultLimit: 6, fuseOptions: { threshold: 0.1 } }"
            @update:model-value="onSelect"
          >
            <template #users-leading="{ item }">
              <UAvatar :src="item.avatar" size="2xs" />
            </template>
          </UCommandPalette>
        </UCard>
      </template>
    </LazyUSlideover>
  </div>
</template>

<script lang="ts" setup>
import { typesafeI18n } from "~/locales/i18n-vue";

const isOpen = ref(false);
const { LL } = typesafeI18n();
const { groups, onSelect } = useSearch();
const route = useRoute();

const handleOpenMenu = () => {
  isOpen.value = !isOpen.value;
};

watch(
  () => route.query,
  (_) => {
    isOpen.value = false;
  },
  { immediate: true, deep: true }
);
</script>
