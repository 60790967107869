<template>
  <ClientOnly>
    <UInput
      icon="i-heroicons-magnifying-glass-20-solid"
      size="md"
      class="w-40 hidden md:block"
      color="white"
      :placeholder="LL.search()"
      @click="openSearch()"
    >
      <template #trailing>
        <div class="flex items-center gap-0.5">
          <UKbd>{{ isMacOS ? "⌘" : "Ctrl" }}</UKbd>
          <UKbd>K</UKbd>
        </div>
      </template>
    </UInput>
    <UModal v-model:open="isOpenSearch">
      <template #content>
        <UCommandPalette
          id="commandPaletteRef"
          ref="commandPaletteRef"
          class="mt-2"
          :empty-state="{
            label: LL.no_results(),
            queryLabel: LL.no_results(),
          }"
          :placeholder="`${LL.search()}...`"
          :groups="groups"
          :fuse="{ resultLimit: 6, fuseOptions: { threshold: 0.1 } }"
          @update:model-value="onSelect"
        >
          <template #users-leading="{ item }">
            <UAvatar :src="item.avatar" size="2xs" />
          </template>
        </UCommandPalette>
      </template>
    </UModal>
  </ClientOnly>
</template>

<script setup lang="ts">
import { typesafeI18n } from "~/locales/i18n-vue";

const emit = defineEmits<{
  toggle: [val?: boolean];
}>();
const keywords = ref("");
const { LL } = typesafeI18n();

const commandPaletteRef = ref();
const { groups, isOpenSearch, openSearch, onSelect } = useSearch();

const isMacOS = computed(() => {
  if (typeof window === "undefined") return false;
  return navigator.platform.toUpperCase().indexOf("MAC") >= 0;
});

defineShortcuts({
  escape: {
    usingInput: true,
    handler: () => {
      keywords.value = "";
      emit("toggle");
    },
  },
  meta_k: {
    usingInput: true,
    handler: () => {
      openSearch();
    },
  },
});
</script>
