import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hooks_hyXe6laRLyyi6S6XoqeItfe9HTFGNswlS09LT9GQbmQ from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/debug-hooks.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_VhYgvIxs7JvcJehrDyNK6mIG5x01oKDEBq4DmVSTbdE from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/modules/i18n/runtime/plugin.ts";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/.nuxt/pwa-icons-plugin.ts";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import _01_initServices_veyu86RevgDVGN2Ko8T5orQOg3BQHVXKRZg61ORsNqA from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/plugins/01.initServices.ts";
import _02_sentry_client_r8VsRL6uaOB6niOe2KdSDlHEN_OsC8qnPLsZ1O1SNgA from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/plugins/02.sentry.client.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  debug_hooks_hyXe6laRLyyi6S6XoqeItfe9HTFGNswlS09LT9GQbmQ,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  plugin_VhYgvIxs7JvcJehrDyNK6mIG5x01oKDEBq4DmVSTbdE,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  _01_initServices_veyu86RevgDVGN2Ko8T5orQOg3BQHVXKRZg61ORsNqA,
  _02_sentry_client_r8VsRL6uaOB6niOe2KdSDlHEN_OsC8qnPLsZ1O1SNgA
]