import validate from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/pages/runtime/validate.js";
import setup_45global from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/middleware/setup.global.ts";
import manifest_45route_45rule from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  setup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-only": () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/middleware/admin-only.ts"),
  "guest-only": () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/middleware/guest-only.ts"),
  "member-only": () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/middleware/member-only.ts")
}