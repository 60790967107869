<template>
  <header
    class="sticky top-0 z-50 w-full backdrop-blur flex-none border-b border-neutral-200 dark:border-neutral-800 bg-white/75 dark:bg-neutral-900/75"
  >
    <UContainer>
      <div class="h-14 flex items-center justify-between gap-3">
        <div class="flex gap-2">
          <div class="lg:flex-1 flex items-center gap-1.5">
            <Logo />
          </div>
          <div class="hidden lg:flex gap-2 items-center">
            <UButton
              v-if="userStore.isAdmin"
              color="secondary"
              variant="soft"
              icon="i-simple-icons-phpmyadmin"
              label="Admin"
              target="_blank"
              class="h-8"
              to="https://kungfutech-admin.pages.dev/"
            />
            <UNavigationMenu :items="items" class="w-full justify-center" />
          </div>
        </div>
        <div class="flex gap-1">
          <ClientOnly>
            <NavbarSearch />
          </ClientOnly>
          <div v-if="userStore.isLogged" class="flex gap-1">
            <UButton
              variant="soft"
              color="warning"
              to="/points/missions"
              icon="i-heroicons-fire-20-solid"
            >
              {{ userStore.point }} <span class="hidden md:block">Points</span>
            </UButton>
            <UButton
              v-if="userStore.isShowUpgradeAccount"
              class="hidden lg:flex"
              variant="soft"
              color="secondary"
              icon="i-heroicons-rocket-launch-solid"
              to="/nang-cap-tai-khoan"
            >
              {{ LL.upgrade() }}
            </UButton>
            <User />
          </div>
          <div v-else class="flex gap-1">
            <ButtonOpenAuthModal />
            <UButton
              color="primary"
              class="hidden lg:block"
              variant="outline"
              to="/dang-ky"
            >
              {{ LL.register() }}
            </UButton>
          </div>
          <div class="flex items-center">
            <PageNavbarToggle />
            <Settings />
          </div>
        </div>
      </div>
    </UContainer>
  </header>
</template>

<script lang="ts" setup>
import User from "./User.vue";
import Settings from "./Settings.vue";
import { useUserStore } from "store/user";
import { typesafeI18n } from "~/locales/i18n-vue";
import { EMarket } from "~/constants/enum";
import ButtonOpenAuthModal from "~/components/AuthModal/ButtonOpenAuthModal.vue";

const { LL } = typesafeI18n();
const userStore = useUserStore();
const { handleChangeMarket } = useChangeSettings();
const route = useRoute();

export interface IMenuItem {
  type: "link" | "button";
  text: string;
  href?: string;
  route?: string;
}

const items = computed(() => [
  {
    label: LL.value.course(),
    icon: "i-lucide-graduation-cap",
    to: "/khoa-hoc",
    children: [
      ...(userStore.isVietnamMarket
        ? [
            {
              label: LL.value.course_name({ courseName: "Javascript" }),
              icon: "i-simple-icons-javascript",
              to: "/khoa-hoc/javascript",
              description: "Học lập trình JavaScript từ cơ bản đến nâng cao",
            },
            {
              label: LL.value.course_name({ courseName: "Java" }),
              icon: "i-simple-icons-coffeescript",
              to: "/khoa-hoc/java",
              description: "Khóa học Java toàn diện cho người mới bắt đầu",
            },
            {
              label: LL.value.course_name({ courseName: "Angular" }),
              icon: "i-simple-icons-angular",
              to: "/khoa-hoc/angular",
              description: "Làm chủ Angular framework phát triển ứng dụng web",
            },
            {
              label: LL.value.course_name({ courseName: "Spring boot" }),
              icon: "i-simple-icons-spring",
              to: "/khoa-hoc/spring-boot",
              description: "Phát triển ứng dụng với Spring Boot framework",
            },
            {
              label: LL.value.course_name({ courseName: "Python" }),
              icon: "i-simple-icons-python",
              to: "/khoa-hoc/python",
              description: "Lập trình Python từ zero đến hero",
            },
            {
              label: LL.value.course_name({ courseName: "Reactjs" }),
              icon: "i-simple-icons-react",
              to: "/khoa-hoc/reactjs",
              description: "Xây dựng ứng dụng web với React.js",
            },
            {
              label: LL.value.course_name({ courseName: "AWS" }),
              icon: "i-simple-icons-amazon",
              to: "/khoa-hoc/aws",
              description: "Làm chủ nền tảng điện toán đám mây AWS",
            },
            {
              label: LL.value.course_name({ courseName: "Go" }),
              icon: "i-simple-icons-go",
              to: "/khoa-hoc/go",
              description: "Học lập trình Go cho phát triển backend",
            },
            {
              label: LL.value.course_name({ courseName: "C" }),
              icon: "i-simple-icons-c",
              to: "/khoa-hoc/c",
              description: "Nền tảng lập trình C cho người mới",
            },
            {
              label: LL.value.course_name({ courseName: "React Native" }),
              icon: "i-simple-icons-react",
              to: "/khoa-hoc/react-native",
              description: "Phát triển ứng dụng di động với React Native",
            },
            {
              label: LL.value.course_name({ courseName: "Docker" }),
              icon: "i-simple-icons-docker",
              to: "/khoa-hoc/docker",
              description: "Container hóa ứng dụng với Docker",
            },
            {
              label: LL.value.course_name({ courseName: "C++" }),
              icon: "i-simple-icons-cplusplus",
              to: "/khoa-hoc/cpp",
              description: "Lập trình C++ từ cơ bản đến nâng cao",
            },
            {
              label: LL.value.course_name({ courseName: "Blockchain" }),
              icon: "i-simple-icons-bitcoin",
              to: "/khoa-hoc/blockchain",
              description: "Tìm hiểu và phát triển ứng dụng Blockchain",
            },
            {
              label: LL.value.course_name({ courseName: "Ruby" }),
              icon: "i-simple-icons-ruby",
              to: "/khoa-hoc/ruby",
              description: "Học Ruby và Ruby on Rails framework",
            },
          ]
        : []),
      ...(userStore.isGlobalMarket
        ? [
            {
              label: "Algorithms",
              icon: "i-simple-icons-thealgorithms",
              to: "/khoa-hoc/algorithms",
              description: "Master data structures and algorithms",
            },
          ]
        : []),
      ...(userStore.isFptMarket
        ? [
            {
              label: "SE",
              icon: "i-simple-icons-flat",
              to: "/khoa-hoc/se",
              description: "Khóa học Software Engineering dành cho sinh viên FPT",
            },
          ]
        : []),
      {
        label: LL.value.all(),
        icon: "i-heroicons-ellipsis-horizontal-20-solid",
        to: "/khoa-hoc",
      },
    ],
  },

  {
    label: LL.value.programming(),
    icon: "i-lucide-code",
    to: "/posts",
    children: [
      {
        label: `${LL.value.the_world_of()} ${LL.value.programming()}`,
        icon: "i-lucide-code",
        to: "/posts",
        description: LL.value.kungfu_posts_quote(),
        active: true,
      },
      {
        label: "TechMely Channel",
        icon: "i-lucide-youtube",
        to: "https://www.youtube.com/c/TechMely?sub_confirmation=1",
        target: "_blank",
        description: LL.value.free_programming_courses_on_youtube_tech_mely(),
      },
      {
        label: "Mely Demy",
        icon: "i-lucide-shopping-cart",
        to: "https://academy.techmely.com/",
        target: "_blank",
        description: LL.value.hunting_for_29k_courses(),
      },
      {
        label: "Mely Tool",
        icon: "i-lucide-wrench",
        to: "https://tools.techmely.com/",
        target: "_blank",
        description: LL.value.tools_for_developers(),
      },
    ],
  },
  {
    label: LL.value.interview(),
    icon: "i-lucide-box",
    badge: "PRO",
    to: "/phong-van-pro/full-stack-web-and-mobile/java",
    active: route.path.startsWith('/phong-van-pro'),
    children: [
      {
        label: LL.value.vietnam_market(),
        icon: "i-lucide-flag",
        description: LL.value.interview_questions_for_vietnam_market(),
        onSelect: () => {
          navigateTo("/phong-van-pro/full-stack-web-and-mobile/java");
          handleChangeMarket(EMarket.Vietnam);
        },
      },
      {
        label: LL.value.global_market(),
        icon: "i-lucide-globe",
        description: LL.value.interview_questions_for_global_market(),
        onSelect: () => {
          navigateTo("/phong-van-pro/full-stack-web-and-mobile-global/java-global");
          console.log("global");
          handleChangeMarket(EMarket.Global);
        },
      },
    ],
  },
]);
</script>
