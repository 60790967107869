import { typesafeI18n } from '~/locales/i18n-vue'
import { useUserStore } from '~/store'

export const useSearch = () => {
  const { LL } = typesafeI18n()
  const router = useRouter()
  const userStore = useUserStore()
  const isOpenSearch = ref(false)

  const openSearch = () => {
    isOpenSearch.value = true
  }

  const groups = computed(() => [
    {
      id: 'navigation',
      items: [
        {
          label: LL.value.home(),
          icon: 'i-lucide-home',
          to: '/',
        },
        {
          label: LL.value.course(),
          icon: 'i-lucide-graduation-cap',
          to: '/khoa-hoc',
        },
        {
          label: LL.value.programming(),
          icon: 'i-lucide-code',
          to: '/posts',
        },
        {
          label: LL.value.interview_questions(),
          icon: 'i-lucide-shield-question',
          to: '/phong-van-pro/full-stack-web-and-mobile/java',
        },
        {
          label: `${LL.value.interview_questions()} global`,
          icon: 'i-lucide-file-question',
          to: '/phong-van-pro/full-stack-web-and-mobile-global/java-global',
        },
        {
          label: LL.value.upgrade_account(),
          icon: 'i-lucide-rocket',
          to: '/nang-cap-tai-khoan',
        },
      ],
    },
    {
      id: 'actions',
      items: [
        {
          label: LL.value.create_articles(),
          icon: 'i-lucide-file-plus',
          to: '/posts/create',
        },
        {
          label: LL.value.my_profile(),
          icon: 'i-lucide-user',
          to: userStore.isLogged ? `/users/${userStore.username}` : '/dang-ky',
        },
      ],
    },
    {
      id: 'courses',
      label: LL.value.course(),
      items: [
        {
          label: LL.value.course_name({ courseName: 'Javascript' }),
          icon: 'i-simple-icons-javascript',
          to: '/khoa-hoc/javascript',
        },
        {
          label: LL.value.course_name({ courseName: 'Java' }),
          icon: 'i-simple-icons-coffeescript',
          to: '/khoa-hoc/java',
        },
        {
          label: LL.value.course_name({ courseName: 'Python' }),
          icon: 'i-simple-icons-python',
          to: '/khoa-hoc/python',
        },
        {
          label: LL.value.course_name({ courseName: 'Reactjs' }),
          icon: 'i-simple-icons-react',
          to: '/khoa-hoc/reactjs',
        },
        {
          label: LL.value.course_name({ courseName: 'Angular' }),
          icon: 'i-simple-icons-angular',
          to: '/khoa-hoc/angular',
        },
        {
          label: LL.value.course_name({ courseName: 'Spring boot' }),
          icon: 'i-simple-icons-spring',
          to: '/khoa-hoc/spring-boot',
        },
        {
          label: LL.value.course_name({ courseName: 'AWS' }),
          icon: 'i-simple-icons-amazonaws',
          to: '/khoa-hoc/aws',
        },
        {
          label: LL.value.course_name({ courseName: 'Go' }),
          icon: 'i-simple-icons-go',
          to: '/khoa-hoc/go',
        },
        {
          label: LL.value.course_name({ courseName: 'C' }),
          icon: 'i-simple-icons-c',
          to: '/khoa-hoc/c',
        },
        {
          label: LL.value.course_name({ courseName: 'React Native' }),
          icon: 'i-simple-icons-react',
          to: '/khoa-hoc/react-native',
        },
        {
          label: LL.value.course_name({ courseName: 'Docker' }),
          icon: 'i-simple-icons-docker',
          to: '/khoa-hoc/docker',
        },
        {
          label: LL.value.course_name({ courseName: 'C++' }),
          icon: 'i-simple-icons-cplusplus',
          to: '/khoa-hoc/cpp',
        },
        {
          label: LL.value.course_name({ courseName: 'Blockchain' }),
          icon: 'i-simple-icons-bitcoin',
          to: '/khoa-hoc/blockchain',
        },
        {
          label: LL.value.course_name({ courseName: 'Ruby' }),
          icon: 'i-simple-icons-ruby',
          to: '/khoa-hoc/ruby',
        },
        {
          label: 'Algorithms',
          icon: 'i-simple-icons-thealgorithms',
          to: '/khoa-hoc/algorithms',
        },
      ],
    },
  ])

  const onSelect = (option) => {
    if (option.click) {
      option.click()
      router.push(option.to)
    } else if (option.to) {
      router.push(option.to)
      isOpenSearch.value = false
    } else if (option.href) {
      window.open(option.href, '_blank')
      router.push(option.to)
    }
  }

  return {
    groups,
    isOpenSearch,
    openSearch,
    onSelect,
  }
}
