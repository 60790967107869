<template>
  <UDropdownMenu :items="items" :popper="{ placement: 'bottom-start' }">
    <UChip
      :text="userStore.role"
      :color="getColorChip"
      position="bottom-left"
      :ui="{ root: 'cursor-pointer', base: 'left-1/2 px-0.5 py-0.5' }"
    >
      <UAvatar :src="userStore.imageUrl" alt="Avatar" loading="lazy" />
    </UChip>
  </UDropdownMenu>
</template>

<script lang="ts" setup>
import { typesafeI18n } from "~/locales/i18n-vue";
import { useUserStore } from "~/store";

const userStore = useUserStore();
const { LL } = typesafeI18n();

const getColorChip = computed(() => {
  switch (userStore.role) {
    case "Diamond":
      return "secondary";
    case "Gold":
      return "warning";
    default:
      return "neutral";
  }
});

const items = computed(() => [
  [
    {
      label: LL.value.my_profile(),
      icon: "i-heroicons-user-circle-20-solid",
      onSelect: () => {
        navigateTo(`/users/${userStore.username}`);
      },
    },
  ],
  [
    {
      label: LL.value.update_profile(),
      icon: "i-heroicons-pencil-square-20-solid",
      onSelect: () => {
        navigateTo("/users/me");
      },
    },
    {
      label: LL.value.change_password(),
      icon: "i-heroicons-wrench-20-solid",
      onSelect: () => {
        navigateTo("/users/me/doi-mat-khau");
      },
    },
    {
      label: LL.value.upgrade_account(),
      icon: "i-heroicons-rocket-launch-20-solid",
      onSelect: () => {
        navigateTo("/nang-cap-tai-khoan");
      },
    },
  ],
  [
    {
      label: LL.value.logout(),
      icon: "i-heroicons-arrow-left-on-rectangle-20-solid",
      onSelect: () => {
        userStore.signOut();
      },
    },
  ],
]);
</script>
