import { ELanguage, EMarket } from '~/constants/enum'
import { Locales } from '~/locales/i18n-types'
import { loadLocaleAsync } from '~/locales/i18n-util.async'
import { typesafeI18n } from '~/locales/i18n-vue'
import { useUserStore } from '~/store'

export function useChangeSettings() {
  const cookieMarket = useCookie('market')
  const userStore = useUserStore()
  const { setLocale } = typesafeI18n()
  const cookieLocale = useCookie('locale')
  const route = useRoute()

  const handleChangeLanguage = async (language: Locales) => {
    await loadLocaleAsync(language)
    setLocale(language)
    cookieLocale.value = language
  }

  const handleChangeMarket = async (market: EMarket) => {
    cookieMarket.value = market
    userStore.updateMarket(market)
    if (market === EMarket.Global) {
      await handleChangeLanguage(ELanguage.En)
      if (route.path.startsWith('/phong-van-pro')) {
        await navigateTo(
          '/phong-van-pro/full-stack-web-and-mobile-global/java-global'
        )
      }
    } else {
      await handleChangeLanguage(ELanguage.Vi)
      if (route.path.startsWith('/phong-van-pro')) {
        await navigateTo('/phong-van-pro/full-stack-web-and-mobile/java')
      }
    }
    reloadNuxtApp({
      force: true,
    })
  }
  return { handleChangeMarket, handleChangeLanguage }
}
