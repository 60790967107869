
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93ea0MUVq_rni_gTgg7lzd0FIDtouFqWE_45kgmuC0ETYjkMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/bai-viet/[category]/[slug].vue?macro=true";
import { default as _91slug_93_YPkTCDFeeFS14XGwrWIbzjhe3c8c9miyIolKyc06G0Meta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/cau-hoi-phong-van/[slug].vue?macro=true";
import { default as dang_45kybj8tOEwop7gmBTiovb47D1sUi4C978vSFj191Z87cxgMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/dang-ky.vue?macro=true";
import { default as fptJ1_45_45pmjK_45gNEiMt109vrXp42dgWIGmfk2Yj_45_RtmjIEMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/fpt.vue?macro=true";
import { default as indextvTbIeFEjOIznhgctfT6lKVe8lJEEEbXide3zfGp2bMMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/index.vue?macro=true";
import { default as jsI0Arjsk4kF_92CbGUC_mJDNq5f_45dmnQrxYRUPGHvpzcMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/js.vue?macro=true";
import { default as _91slug_93UMjkaH7_xRfRxi_45UqwDxyArxNO_45FsMGXRFgnTlkyJ2sMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/khoa-hoc/[slug].vue?macro=true";
import { default as createsUDh04T5qtJ50y7FXtNWuJPb0Hesbg1DR844qyzg4OoMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/khoa-hoc/create.vue?macro=true";
import { default as _91slug_93lFWNALLnUrR4GOD_OQJGbxWseMEfEDjBwBEHHtk9EToMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/khoa-hoc/edit/[slug].vue?macro=true";
import { default as indexO_454X63V4Jmv5bwYWWtMZqTX6rPHqRONo0pDTIch7e1UMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/khoa-hoc/index.vue?macro=true";
import { default as _91slug_93shT6ZQGneguklF90n2YHZdsdYjJ_45NUK3gM5AgcK2udgMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/khoa-hoc/the-loai/[slug].vue?macro=true";
import { default as loadingYAFeEzZJZoa6dxg75MlLXnEA0SaTcfZEUfol3XASBSIMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/loading.vue?macro=true";
import { default as nang_45cap_45tai_45khoan4QF6BJtNgW1FVAGLY3p8tVQrrvS_LIH6c9TN_9dY5aAMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/nang-cap-tai-khoan.vue?macro=true";
import { default as cancelO8IBmjglsQO6RC6ZeVzHx3XGD7HSdpWoLypWhyYfcdAMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/payment/cancel.vue?macro=true";
import { default as success8oOXf9ntsSlRuiS_yrcO3q4u58NIFwX2VFeU0WOuRnIMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/payment/success.vue?macro=true";
import { default as _91category_93FsxELvFETz_454EyZr5zTxlYLXEmEGSpkalj_459vvh_A4QMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/phong-van-pro/[topic]/[category].vue?macro=true";
import { default as index2Y9QxZ1qmM1CHN08R_H7StyjSUkVkYULdZva4dbnYqgMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/phong-van-pro/index.vue?macro=true";
import { default as missions4UcG5wBlK6xx4VOXI6FHs_1mBHA88eDx_45kttp3a5bKQMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/points/missions.vue?macro=true";
import { default as rewardsMSmZ13l_45nqBvwgOpr4L13mAQ1eEGJsfc2vHO87ahonoMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/points/rewards.vue?macro=true";
import { default as _91slug_93qcqxCSA0Yn2_45nbpXL1lU4FnCpH6xElSUePDQuN8_45sGwMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/[slug].vue?macro=true";
import { default as bookmark5yiH__V436gwLpNvWNsN0K_qFT_esOIIhzT8mN4_45FxsMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/bookmark.vue?macro=true";
import { default as createAoCJnLWleSab4dxXNOspFuaPOYOQdpdM_Bytr9ZqpKwMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/create.vue?macro=true";
import { default as _91slug_93jVyvQDcLN_45DCT_nFP3oXQpr1d_pV5uYdOm7hbwWevy0Meta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/edit/[slug].vue?macro=true";
import { default as followingWMfGb2vu0ejJo84x62LwbUoFJdcq_45yJyoXO9UVT9Ho4Meta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/following.vue?macro=true";
import { default as indexkukUdhwO3Qx5UVFJFVgX2L2_AEhL4gi2HYIGV38rgeQMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/index.vue?macro=true";
import { default as _91slug_93uowYtgHMiTjyFiJEQ6Yp07EBWEPw7NREtiKx8rKZq_454Meta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/topics/[slug].vue?macro=true";
import { default as privacy_45policy_eMPir1v1ZjmQ8X4YA8u49CHbYHqge_futPq25_45RM9sMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/privacy-policy.vue?macro=true";
import { default as tai_45khoanING28GobxRycn8y_z2LSXs3Uq8SwMo_I_2iWlUKBZsUMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/tai-khoan.vue?macro=true";
import { default as _91username_93_N533tlbxvIal9kbA3VW8XrlJgsR3xuXLay588LNRtAMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/users/[username].vue?macro=true";
import { default as doi_45mat_45khaukiVJSvGUPyADKvChYooqhaipjp8HtMfyG4hDo1BDm0gMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/users/me/doi-mat-khau.vue?macro=true";
import { default as feedbackN_45RHWYxG8P5duatTwe7GH4ImPMZ2vVt6qZ74QvLt0tYMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/users/me/feedback.vue?macro=true";
import { default as indexnqGryOJDUQWQ0zPtuvHQv_Vo6pFLhegHY_uClOKvfyoMeta } from "/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/users/me/index.vue?macro=true";
export default [
  {
    name: "bai-viet-category-slug",
    path: "/bai-viet/:category()/:slug()",
    meta: _91slug_93ea0MUVq_rni_gTgg7lzd0FIDtouFqWE_45kgmuC0ETYjkMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/bai-viet/[category]/[slug].vue")
  },
  {
    name: "cau-hoi-phong-van-slug",
    path: "/cau-hoi-phong-van/:slug()",
    meta: _91slug_93_YPkTCDFeeFS14XGwrWIbzjhe3c8c9miyIolKyc06G0Meta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/cau-hoi-phong-van/[slug].vue")
  },
  {
    name: "dang-ky",
    path: "/dang-ky",
    meta: dang_45kybj8tOEwop7gmBTiovb47D1sUi4C978vSFj191Z87cxgMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/dang-ky.vue")
  },
  {
    name: "fpt",
    path: "/fpt",
    meta: fptJ1_45_45pmjK_45gNEiMt109vrXp42dgWIGmfk2Yj_45_RtmjIEMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/fpt.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indextvTbIeFEjOIznhgctfT6lKVe8lJEEEbXide3zfGp2bMMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/index.vue")
  },
  {
    name: "js",
    path: "/js",
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/js.vue")
  },
  {
    name: "khoa-hoc-slug",
    path: "/khoa-hoc/:slug()",
    meta: _91slug_93UMjkaH7_xRfRxi_45UqwDxyArxNO_45FsMGXRFgnTlkyJ2sMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/khoa-hoc/[slug].vue")
  },
  {
    name: "khoa-hoc-create",
    path: "/khoa-hoc/create",
    meta: createsUDh04T5qtJ50y7FXtNWuJPb0Hesbg1DR844qyzg4OoMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/khoa-hoc/create.vue")
  },
  {
    name: "khoa-hoc-edit-slug",
    path: "/khoa-hoc/edit/:slug()",
    meta: _91slug_93lFWNALLnUrR4GOD_OQJGbxWseMEfEDjBwBEHHtk9EToMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/khoa-hoc/edit/[slug].vue")
  },
  {
    name: "khoa-hoc",
    path: "/khoa-hoc",
    meta: indexO_454X63V4Jmv5bwYWWtMZqTX6rPHqRONo0pDTIch7e1UMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/khoa-hoc/index.vue")
  },
  {
    name: "khoa-hoc-the-loai-slug",
    path: "/khoa-hoc/the-loai/:slug()",
    meta: _91slug_93shT6ZQGneguklF90n2YHZdsdYjJ_45NUK3gM5AgcK2udgMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/khoa-hoc/the-loai/[slug].vue")
  },
  {
    name: "loading",
    path: "/loading",
    meta: loadingYAFeEzZJZoa6dxg75MlLXnEA0SaTcfZEUfol3XASBSIMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/loading.vue")
  },
  {
    name: "nang-cap-tai-khoan",
    path: "/nang-cap-tai-khoan",
    meta: nang_45cap_45tai_45khoan4QF6BJtNgW1FVAGLY3p8tVQrrvS_LIH6c9TN_9dY5aAMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/nang-cap-tai-khoan.vue")
  },
  {
    name: "payment-cancel",
    path: "/payment/cancel",
    meta: cancelO8IBmjglsQO6RC6ZeVzHx3XGD7HSdpWoLypWhyYfcdAMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/payment/cancel.vue")
  },
  {
    name: "payment-success",
    path: "/payment/success",
    meta: success8oOXf9ntsSlRuiS_yrcO3q4u58NIFwX2VFeU0WOuRnIMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/payment/success.vue")
  },
  {
    name: "phong-van-pro-topic-category",
    path: "/phong-van-pro/:topic()/:category()",
    meta: _91category_93FsxELvFETz_454EyZr5zTxlYLXEmEGSpkalj_459vvh_A4QMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/phong-van-pro/[topic]/[category].vue")
  },
  {
    name: "phong-van-pro",
    path: "/phong-van-pro",
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/phong-van-pro/index.vue")
  },
  {
    name: "points-missions",
    path: "/points/missions",
    meta: missions4UcG5wBlK6xx4VOXI6FHs_1mBHA88eDx_45kttp3a5bKQMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/points/missions.vue")
  },
  {
    name: "points-rewards",
    path: "/points/rewards",
    meta: rewardsMSmZ13l_45nqBvwgOpr4L13mAQ1eEGJsfc2vHO87ahonoMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/points/rewards.vue")
  },
  {
    name: "posts-slug",
    path: "/posts/:slug()",
    meta: _91slug_93qcqxCSA0Yn2_45nbpXL1lU4FnCpH6xElSUePDQuN8_45sGwMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/[slug].vue")
  },
  {
    name: "posts-bookmark",
    path: "/posts/bookmark",
    meta: bookmark5yiH__V436gwLpNvWNsN0K_qFT_esOIIhzT8mN4_45FxsMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/bookmark.vue")
  },
  {
    name: "posts-create",
    path: "/posts/create",
    meta: createAoCJnLWleSab4dxXNOspFuaPOYOQdpdM_Bytr9ZqpKwMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/create.vue")
  },
  {
    name: "posts-edit-slug",
    path: "/posts/edit/:slug()",
    meta: _91slug_93jVyvQDcLN_45DCT_nFP3oXQpr1d_pV5uYdOm7hbwWevy0Meta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/edit/[slug].vue")
  },
  {
    name: "posts-following",
    path: "/posts/following",
    meta: followingWMfGb2vu0ejJo84x62LwbUoFJdcq_45yJyoXO9UVT9Ho4Meta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/following.vue")
  },
  {
    name: "posts",
    path: "/posts",
    meta: indexkukUdhwO3Qx5UVFJFVgX2L2_AEhL4gi2HYIGV38rgeQMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/index.vue")
  },
  {
    name: "posts-topics-slug",
    path: "/posts/topics/:slug()",
    meta: _91slug_93uowYtgHMiTjyFiJEQ6Yp07EBWEPw7NREtiKx8rKZq_454Meta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/posts/topics/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policy_eMPir1v1ZjmQ8X4YA8u49CHbYHqge_futPq25_45RM9sMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/privacy-policy.vue")
  },
  {
    name: "tai-khoan",
    path: "/tai-khoan",
    meta: tai_45khoanING28GobxRycn8y_z2LSXs3Uq8SwMo_I_2iWlUKBZsUMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/tai-khoan.vue")
  },
  {
    name: "users-username",
    path: "/users/:username()",
    meta: _91username_93_N533tlbxvIal9kbA3VW8XrlJgsR3xuXLay588LNRtAMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/users/[username].vue")
  },
  {
    name: "users-me-doi-mat-khau",
    path: "/users/me/doi-mat-khau",
    meta: doi_45mat_45khaukiVJSvGUPyADKvChYooqhaipjp8HtMfyG4hDo1BDm0gMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/users/me/doi-mat-khau.vue")
  },
  {
    name: "users-me-feedback",
    path: "/users/me/feedback",
    meta: feedbackN_45RHWYxG8P5duatTwe7GH4ImPMZ2vVt6qZ74QvLt0tYMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/users/me/feedback.vue")
  },
  {
    name: "users-me",
    path: "/users/me",
    meta: indexnqGryOJDUQWQ0zPtuvHQv_Vo6pFLhegHY_uClOKvfyoMeta || {},
    component: () => import("/Users/thaycacac/Documents/CODE/techmely/kungfutech-fe-v2/pages/users/me/index.vue")
  }
]