export default defineAppConfig({
  name: 'KungFuTech',
  description:
    'KungFuTech là nơi giúp bạn học lập trình online, chia sẻ kiến thức lập trình bao gồm tài liệu học lập trình, các khóa học lập trình',
  url: 'https://kungfutech.edu.vn',
  author: {
    name: 'thaycacac',
    link: 'https://github.com/thaycacac',
  },
  ui: {
    colors: {
      primary: 'teal',
      neutral: 'slate',
      secondary: 'fuchsia',
      error: 'pink',
      info: 'cyan',
      success: 'green',
      warning: 'amber',
    },
    card: {
      slots: {
        body: 'px-3 py-3 sm:p-3',
      },
    },
    button: {
      slots: {
        base: 'cursor-pointer',
      },
      variants: {
        variant: {
          link: '!px-0 !py-0 hover:underline',
        },
      },
    },
    notifications: {
      position: 'top-0 bottom-auto',
    },
    input: {
      slots: {
        root: 'w-full',
      },
    },
  },
  organization: {
    name: 'Techmely',
    url: 'https://techmely.com',
    members: [
      {
        name: 'Trần Quang Nhật',
        nickname: 'harrytran998',
        jobTitle: 'Founder',
        avatarUrl: '',
        relatedLinks: [],
      },
      {
        name: 'Phạm Ngọc Hoà',
        nickname: 'thaycacac',
        jobTitle: 'Founder',
        avatarUrl: '',
        relatedLinks: [],
      },
      {
        name: 'Đỗ Quang Hiệp',
        nickname: 'flame',
        jobTitle: 'Co-Founder',
        avatarUrl: '',
        relatedLinks: [],
      },
    ],
  },
})
