<template>
  <ClientOnly>
    <UDropdownMenu
      :items="items"
      :ui="{
        content: 'w-48',
      }"
    >
      <UButton
        title="settings"
        aria-label="settings"
        color="neutral"
        variant="outline"
        icon="i-heroicons-computer-desktop-solid"
      />

      <template #header="{ item }">
        <div class="text-left">
          <p>{{ item.label }}</p>
        </div>
      </template>

      <template #market="{ item }">
        <div class="flex justify-between w-full">
          <span class="truncate">{{ item.label }}</span>
          <UIcon
            :name="item.icon"
            class="flex-shrink-0 h-4 w-4 text-neutral-400 dark:text-neutral-500"
          />
        </div>
      </template>

      <template #language="{ item }">
        <div class="flex justify-between w-full">
          <span class="truncate">{{ item.label }}</span>
          <span>{{ item.icon }}</span>
        </div>
      </template>
    </UDropdownMenu>
  </ClientOnly>
</template>

<script setup lang="ts">
import { EMarket, EThemColor } from "../../../constants/enum";
import { useUserStore } from "../../../store/user";
import { typesafeI18n } from "~/locales/i18n-vue";

const userStore = useUserStore();
const cookieMarket = useCookie("market");
const { locale, LL } = typesafeI18n();
const colorMode = useColorMode();

const { handleChangeLanguage, handleChangeMarket } = useChangeSettings();

const handleChangeTheme = (value) => {
  colorMode.preference = value;
  colorMode.value = value;
};

const items = computed(() => [
  [
    {
      slot: "header",
      label: LL.value.language(),
      disabled: true,
      type: "label",
    },
    {
      slot: "language",
      label: "Việt Nam",
      iso: "vi",
      icon: "🇻🇳",
      class:
        locale.value === "vi"
          ? "bg-neutral-100 border-[1px] border-neutral-200 dark:bg-neutral-700 dark:border-neutral-600 rounded-sm cursor-pointer"
          : "cursor-pointer",
      onSelect: () => handleChangeLanguage("vi"),
    },
    {
      slot: "language",
      label: "English",
      iso: "en",
      icon: "🇺🇸",
      class:
        locale.value === "en"
          ? "bg-neutral-100 border-[1px] border-neutral-200 dark:bg-neutral-700 dark:border-neutral-600 rounded-sm cursor-pointer"
          : "cursor-pointer",
      onSelect: () => handleChangeLanguage("en"),
    },
  ],
  [
    {
      slot: "header",
      label: LL.value.market(),
      disabled: true,
      type: "label",
    },
    {
      slot: "market",
      label: "Global",
      icon: "i-heroicons-globe-asia-australia-solid",
      class:
        cookieMarket.value === EMarket.Global
          ? "bg-neutral-100 border-[1px] border-neutral-200 dark:bg-neutral-700 dark:border-neutral-600 rounded-sm cursor-pointer"
          : "cursor-pointer",
      onSelect: () => handleChangeMarket(EMarket.Global),
    },
    {
      slot: "market",
      label: "Việt Nam",
      icon: "i-heroicons-map-pin-solid",
      class:
        cookieMarket.value === EMarket.VietNam
          ? "bg-neutral-100 border-[1px] border-neutral-200 dark:bg-neutral-700 dark:border-neutral-600 rounded-sm cursor-pointer"
          : "cursor-pointer",
      onSelect: () => handleChangeMarket(EMarket.VietNam),
    },
    ...(userStore.isFpter
      ? [
          {
            slot: "market",
            label: "FPT",
            icon: "i-heroicons-academic-cap-solid",
            class:
              cookieMarket.value === EMarket.Fpt
                ? "bg-neutral-100 border-[1px] border-neutral-200 dark:bg-neutral-700 dark:border-neutral-600 rounded-sm cursor-pointer"
                : "cursor-pointer",
            onSelect: () => handleChangeMarket(EMarket.Fpt),
          },
        ]
      : []),
  ],
  [
    {
      slot: "header",
      label: LL.value.color_mode(),
      disabled: true,
      type: "label",
    },
    {
      slot: "market",
      label: LL.value.system(),
      icon: "i-heroicons-computer-desktop-solid",
      class:
        colorMode.preference === EThemColor.System
          ? "bg-neutral-100 border-[1px] border-neutral-200 dark:bg-neutral-700 dark:border-neutral-600 rounded-sm cursor-pointer"
          : "cursor-pointer",
      onSelect: () => handleChangeTheme(EThemColor.System),
    },
    {
      slot: "market",
      label: LL.value.light(),
      icon: "i-heroicons-sun-20-solid",
      class:
        colorMode.preference === EThemColor.Light
          ? "bg-neutral-100 border-[1px] border-neutral-200 dark:bg-neutral-700 dark:border-neutral-600 rounded-sm cursor-pointer"
          : "cursor-pointer",
      onSelect: () => handleChangeTheme(EThemColor.Light),
    },
    {
      slot: "market",
      label: LL.value.dark(),
      icon: "i-heroicons-moon-20-solid",
      class:
        colorMode.preference === EThemColor.Dark
          ? "bg-neutral-100 border-[1px] border-neutral-200 dark:bg-neutral-700 dark:border-neutral-600 rounded-sm cursor-pointer"
          : "cursor-pointer",
      onSelect: () => handleChangeTheme(EThemColor.Dark),
    },
  ],
]);
</script>
